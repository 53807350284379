<template>
  <div class="hospedajes-reports-table">
    <!-- BUTTONS -->
    <b-row v-if="!loading" class="mb-1 d-flex flex-wrap">
      <b-col>
        <b-button
          :disabled="allItemsSelected"
          variant="link"
          size="sm"
          @click="selectAllItems"
        >
          Seleciona'ls tots
        </b-button>
        <b-button
          v-if="selectedItems.length"
          variant="link"
          class="ml-1"
          size="sm"
          @click="clearSelection"
        >
          Esborrar selecció
        </b-button>
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          ref="hospedajesReportsTable"
          responsive
          class="position-relative"
          :busy="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="items"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :fields="fields"
          selectable
          select-mode="multi"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <div class="d-flex align-items-center">
              <template v-if="data.rowSelected">
                <b-form-checkbox
                  :ref="`checkbox-${data.value}`"
                  value="1"
                  checked="1"
                  disabled
                  class="mr-1"
                />
              </template>
              <template v-else>
                <b-form-checkbox
                  :id="`checkbox-${data.value}`"
                  disabled
                  class="mr-1"
                />
              </template>

              <b-booking-localizator
                :localizator="data.value"
                :source="data.item.source"
                :highlighted="data.item.highlighted"
                :fictitious="data.item.fictitious"
              />
            </div>
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: client -->
          <template #cell(client)="data">
            <b-client :uuid="data.item.clientUuid" :name="data.value" />
          </template>

          <!-- Column: hospedajes -->
          <template #cell(hospedajes)="data">
            <div
              v-if="
                !data.item.hospedajesOwnerCode ||
                !data.item.hospedajesAccommodationCode
              "
              class="text-nowrap"
            >
              <feather-icon icon="AlertTriangleIcon" class="text-danger mr-1" />
              <span>No disponible</span>
            </div>
            <div v-else class="d-flex flex-column">
              <span class="text-nowrap">
                Arrendador: {{ data.item.hospedajesOwnerCode }}
              </span>
              <span>
                Establecimiento: {{ data.item.hospedajesAccommodationCode }}
              </span>
            </div>
          </template>

          <!-- Column: checkin -->
          <template #cell(checkin)="data">
            {{ formatDate(data.value) }}
          </template>

          <!-- Column: checkout -->
          <template #cell(checkout)="data">
            {{ formatDate(data.value) }}
          </template>

          <!-- Column: reportableGuests -->
          <template #cell(reportableGuests)="data">
            <b-badge :variant="getBadgeVariant(data.value.length)">
              {{ data.value.length }}
            </b-badge>
          </template>

          <!-- Column: already sent? -->
          <template #cell(sent)="data">
            <b-badge v-if="!data.item.hospedajesReportSent" variant="dark">
              No
            </b-badge>
            <div v-else-if="data.item.hospedajesReportError">
              <b-badge variant="danger" class="mr-50"> Error </b-badge>
              <small>
                {{ data.item.hospedajesReportErrorMessage }}
              </small>
            </div>
            <b-badge v-else variant="success"> Sí </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormCheckbox,
  BSpinner,
  BButton,
  BBadge,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import BClient from "@foravila-core/components/b-client/BClient.vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormCheckbox,
    BSpinner,
    BBookingLocalizator,
    BAccommodation,
    BClient,
    BButton,
    BBadge,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "checkin",
      sortDesc: false,
      fields: [
        { key: "localizator", label: this.$t("Nº reserva") },
        { key: "client", label: this.$t("Client") },
        { key: "accommodation", label: this.$t("Allotjament") },
        { key: "hospedajes", label: this.$t("Registre Hospedajes") },
        { key: "checkin", label: this.$t("Entrada"), sortable: true },
        { key: "checkout", label: this.$t("Sortida"), sortable: true },
        { key: "reportableGuests", label: this.$t("Hostes confirmats") },
        { key: "sent", label: this.$t("Enviat?") },
      ],
      selectedItem: null,
      selectedItems: [],
    };
  },
  computed: {
    allItemsSelected() {
      return this.items.length === this.selectedItems.length;
    },
  },
  watch: {
    selectedItems(items) {
      this.$emit(
        "items-selected",
        items.map((item) => item.bookingStatsUuid)
      );
    },
  },
  methods: {
    onRowSelected(items) {
      this.selectedItems = items;
    },
    formatDate(date) {
      return formatDateStringToDate(date);
    },
    selectAllItems() {
      this.$refs.hospedajesReportsTable.selectAllRows();
    },
    clearSelection() {
      this.$refs.hospedajesReportsTable.clearSelected();
    },
    getBadgeVariant(value) {
      if (!value) return "dark";
      return "primary";
    },
  },
};
</script>
