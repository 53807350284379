<template>
  <span class="text-nowrap d-flex align-items-center">
    <b-avatar
      v-if="source"
      v-b-tooltip.hover.left="bookingSourceName"
      :src="bookingSourceImage"
      size="24"
      class="mr-25"
    />
    <span
      class="localizator cursor-pointer"
      :class="{
        'text-decoration-line-through text-danger': cancelled,
      }"
      @click.exact.left.stop="openBooking"
      @click.exact.left.meta.stop="openBookingInNewTab"
      @click.exact.left.ctrl.stop="openBookingInNewTab"
      @click.exact.middle.stop="openBookingInNewTab"
    >
      {{ localizator }}
    </span>
    <feather-icon
      v-if="highlighted"
      v-b-tooltip.hover.right="$t('Reserva destacada')"
      icon="StarIcon"
      class="fill-current text-primary ml-25"
    />
    <feather-icon
      v-if="fictitious"
      v-b-tooltip.hover.right="$t('Reserva especial')"
      icon="UmbrellaIcon"
      class="fill-current text-info ml-25"
    />
  </span>
</template>

<script>
import { BAvatar, VBTooltip } from "bootstrap-vue";
import { getOtaName, getBookingSourceImage } from "@/utils/methods";

export default {
  components: {
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    source: {
      type: String,
      default: null,
    },
    localizator: {
      type: String,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    cancelled: {
      type: Boolean,
      default: false,
    },
    fictitious: {
      type: Boolean,
      default: false,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bookingViewRoute() {
      return {
        name: "foravila-booking-view",
        params: { localizator: this.localizator },
      };
    },
    bookingSourceName() {
      return getOtaName(this.source);
    },
    bookingSourceImage() {
      return getBookingSourceImage(this.source);
    },
  },
  methods: {
    openBooking() {
      this.$router.push(this.bookingViewRoute);
    },
    openBookingInNewTab() {
      const routeData = this.$router.resolve(this.bookingViewRoute);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
span.localizator:hover {
  color: #ffcd00 !important;
}
</style>
